import { LoadingSection } from './LoadingSection';
import { VariationSelector } from './VariationSelector';
import { Section, Variation } from '../../api';

interface UpdateSectionsProps extends VariantClickProps {
  nextRecipeData: Recipe;
}

interface TemplateSectionsProps {
  readonly updateSections: (updateSectionsProps: UpdateSectionsProps) => void;
  readonly template: string;
  readonly sections: Section[] | undefined;
  readonly reloadSections: () => Promise<void>;
  readonly variations: Variation[];
  readonly updateVariations: () => Promise<void>;
  readonly selectedVariations: Record<string, string[]>;
  readonly recipeData: Recipe;
  readonly filter: string;
}

export default function TemplateSections({
  updateSections,
  template,
  sections,
  reloadSections,
  variations,
  updateVariations,
  selectedVariations,
  recipeData,
  filter,
}: TemplateSectionsProps) {
  const handleVariantClick = ({
    sectionId,
    variantId,
    checked,
  }: VariantClickProps) => {
    updateSections({
      nextRecipeData: recipeData,
      template,
      sectionId,
      variantId,
      checked,
    });
  };

  if (sections) {
    if (sections.length > 0) {
      return (
        <div className="rb-flex rb-flex-col rb-w-full">
          {sections.map(section => (
            <VariationSelector
              key={section.id}
              template={template}
              section={section}
              reloadSections={reloadSections}
              variations={variations?.filter(
                variation => variation.type === section.type,
              )}
              updateVariations={updateVariations}
              selectedVariations={[
                ...(selectedVariations[`${template}#${section.id}`] || []),
                ...(selectedVariations[section.id] || []),
              ]}
              handleVariantClick={handleVariantClick}
              filter={filter}
            />
          ))}
        </div>
      );
    }
    return <p>No sections available</p>;
  }
  return LoadingSection('sections');
}
