import { useEffect, useState, useCallback } from 'react';

import { Button, Tooltip } from '@shopify/polaris';

import { SectionName } from './SectionName';
import styles from './styles.module.css';
import VariationOption from './VariationOption';
import { Section, Variation } from '../../api';
import { useShopStatus } from '../../graph';
import CreateVariationModal from '../CreateVariationModal';
import Switch from '../Switch';

type VariationSelectorProps = {
  template: string;
  section: Section;
  reloadSections: () => Promise<void>;
  variations: Variation[];
  updateVariations: () => Promise<void>;
  selectedVariations: string[];
  handleVariantClick: (variantClickProps: VariantClickProps) => void;
  filter: string;
};

export function VariationSelector({
  template,
  section,
  reloadSections,
  variations,
  updateVariations,
  selectedVariations,
  handleVariantClick,
  filter,
}: VariationSelectorProps): JSX.Element {
  const { title, id } = section;
  const [collapsed, setCollapsed] = useState(true);
  const [hasCompatible, setHasCompatible] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [newVariationLoading, setNewVariationLoading] = useState(false);
  const [newVariationModalOpen, setNewVariationModalOpen] = useState(false);
  const [filteredVariations, setFilteredVariations] = useState<Variation[]>([]);
  const [copy, setCopy] = useState<Variation | undefined>(undefined);
  const { themeSettings, myshopifyDomain } = useShopStatus();

  const handleNewVariation = async () => {
    if (section.type === 'pagefly-section') {
      window.open(
        `https://${myshopifyDomain}/admin/apps/pagefly/sections`,
        '_blank',
      );
      return;
    }
    setCopy(undefined);
    setNewVariationLoading(true);
    setNewVariationModalOpen(true);
  };

  useEffect(
    () => () => {
      setCollapsed(false);
      setNewVariationLoading(false);
      setNewVariationModalOpen(false);
    },
    [],
  );
  useEffect(() => {
    if (newVariationLoading && !newVariationModalOpen) {
      setNewVariationLoading(false);
    }
  }, [newVariationLoading, newVariationModalOpen, setNewVariationLoading]);

  useEffect(() => {
    setHasCompatible(
      variations?.some(
        variation =>
          (variation.sectionId && variation.sectionId !== section.id) ||
          (variation.template && variation.template !== template),
      ),
    );
  }, [variations, template, section.id]);

  useEffect(() => {
    setFilteredVariations(
      variations
        ?.filter(
          variation =>
            variation.name.toLowerCase().includes(filter.toLowerCase()) &&
            (showAll ||
              selectedVariations.includes(variation.id) ||
              !variation.sectionId ||
              !variation.template ||
              (variation.sectionId === section.id &&
                variation.template === template)),
        )
        .sort((a, b) => variationSort(a, b, selectedVariations)) ?? [],
    );
  }, [
    variations,
    filter,
    collapsed,
    showAll,
    selectedVariations,
    template,
    section.id,
  ]);

  const handleShowMore = () => {
    setCollapsed(!collapsed);
  };

  const handleVariantDuplicate = useCallback(
    (variation: Variation) => {
      const key = `ctx-${variation.id.split('#')[1].toLocaleLowerCase()}`;
      const duplicate = themeSettings?.current?.sections?.[key];
      if (duplicate) {
        setCopy({
          ...variation,
          name: `${variation.name} (copy)`,
          settings: duplicate.settings,
          blocks: duplicate.blocks,
          block_order: duplicate.block_order,
        });
      } else {
        setCopy(variation);
      }
      setNewVariationModalOpen(true);
    },
    [themeSettings],
  );

  return (
    <div className={`${styles.block} rb-mb-3.6`}>
      <div className={styles.blockHead}>
        <div className={styles.blockHeadLeft}>
          <SectionName name={title} id={id} reloadSections={reloadSections} />
        </div>
        <div className={styles.blockHeadRight}>
          <div className="rb-flex rb-items-center rb-bg-[#a4e8f2] rb-rounded-2xl rb-px-2 rb-mx-2 rb-font-normal">
            {section.type}
          </div>
          <div className="">
            <Button
              onClick={handleNewVariation}
              loading={newVariationLoading}
              size="slim"
            >
              + Variation
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.blockBody}>
        {filteredVariations.length > 0 ? (
          filteredVariations
            .map(variation => (
              <VariationOption
                key={variation.id}
                template={template}
                sectionId={section.id}
                handleVariantClick={handleVariantClick}
                variation={variation}
                selected={selectedVariations.includes(variation.id)}
                handleVariantDuplicate={handleVariantDuplicate}
                updateVariations={updateVariations}
              />
            ))
            .slice(0, collapsed ? 5 : undefined)
        ) : (
          <div className="rb-flex rb-justify-center rb-py-10 rb-shadow-[inset_0px_-1px_0px_#e1e3e5]">
            <Button primary onClick={handleNewVariation}>
              Add content
            </Button>
          </div>
        )}
      </div>
      <div className={styles.blockFooter}>
        <div className={styles.showMore}>
          <div>
            Showing{' '}
            {collapsed && filteredVariations.length > 5
              ? 5
              : filteredVariations.length}{' '}
            out of {filteredVariations.length} variations
          </div>
          {filteredVariations.length <= 5 ? null : (
            <div className="rb-flex rb-justify-center">
              <div className="rb-mx-1">-</div>
              <Button plain onClick={handleShowMore}>
                Show {collapsed ? 'more' : 'less'}
              </Button>
            </div>
          )}
        </div>
        {hasCompatible ? (
          <div className={styles.showAll}>
            <Tooltip
              preferredPosition="below"
              content="Variations are shared across the same section types (ie. banner). Include all, will display variations created in other compatible section types."
            >
              <p>Include all</p>
            </Tooltip>
            <Switch value={showAll} setValue={setShowAll} />
          </div>
        ) : null}
      </div>
      <CreateVariationModal
        template={template}
        section={section}
        copy={copy}
        active={newVariationModalOpen}
        setActive={setNewVariationModalOpen}
        updateVariations={updateVariations}
      />
    </div>
  );
}

function variationSort(
  a: Variation,
  b: Variation,
  selectedVariations: string[],
): number {
  // Put selected variation at the top.
  // selectedVariations is an array because we eventually may support multiple
  // selections, but for now this list always has length 1.
  const aSelected = selectedVariations.includes(a.id);
  const bSelected = selectedVariations.includes(b.id);
  if (aSelected === bSelected) {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }
  return aSelected ? -1 : 1;
}
