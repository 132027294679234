import { useCallback, useEffect, useState } from 'react';

import { Button, Checkbox, InlineError, TextField } from '@shopify/polaris';
import {
  CancelSmallMinor,
  DuplicateMinor,
  TickMinor,
} from '@shopify/polaris-icons';

import styles from './styles.module.css';
import { Variation, useFetchJson } from '../../api';
import { useShopStatus } from '../../graph';
import DeleteVariationModal from '../DeleteVariationModal';
import PopoverMenu from '../PopoverMenu';

interface VariationProps {
  readonly variation: Variation;
  readonly template: string;
  readonly sectionId: string;
  readonly selected: boolean;
  readonly handleVariantClick: (variantClickProps: VariantClickProps) => void;
  readonly handleVariantDuplicate: (variation: Variation) => void;
  readonly updateVariations: () => Promise<void>;
}

export default function VariationOption({
  variation,
  template,
  sectionId,
  selected,
  handleVariantClick,
  handleVariantDuplicate,
  updateVariations,
}: VariationProps) {
  const fetch = useFetchJson();
  const { myshopifyDomain } = useShopStatus();
  const [editMode, setEditMode] = useState(false);
  const [updatedName, setUpdatedName] = useState(variation.name);
  const [inputError, setInputError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isPagefly, setIsPagefly] = useState(false);

  useEffect(() => {
    setIsPagefly(
      variation.type === 'pagefly-section' && variation.id.includes('#pf-'),
    );
  }, [variation.type, variation.id]);

  useEffect(() => {
    setUpdatedName(variation.name);
  }, [variation.name]);

  const handleNameChange = useCallback((value: string) => {
    setInputError(false);
    setUpdatedName(value);
  }, []);

  const handleNameSubmit = useCallback(async () => {
    if (updatedName === variation.name) {
      setEditMode(false);
      return;
    }

    if (!updatedName) {
      setInputError(true);
      return;
    }

    setLoading(true);

    await fetch('post', `/variations/${encodeURIComponent(variation.id)}`, {
      ...variation,
      name: updatedName,
    });

    await updateVariations();

    setLoading(false);
    setEditMode(false);
  }, [updatedName, variation, fetch, updateVariations]);

  const handleCancel = useCallback(() => {
    setUpdatedName(variation.name);
    setEditMode(false);
    setInputError(false);
  }, [variation.name]);

  const handleClick = useCallback(
    (checked: boolean) =>
      handleVariantClick({
        template,
        sectionId,
        checked,
        variantId: variation.id,
      }),
    [handleVariantClick, template, sectionId, variation.id],
  );

  const handleEdit = async () => {
    window.open(
      isPagefly
        ? `https://${myshopifyDomain}/admin/apps/pagefly/sections`
        : `/api/admin/shopify/edit/?shop=${myshopifyDomain}&variation=${encodeURIComponent(
            variation.id,
          )}`,
      '_blank',
    );
  };

  return (
    <div
      key={variation.id}
      className={`${styles.blockVariation} ${selected ? styles.active : ''}`}
      role="radio"
      aria-checked={selected}
      tabIndex={0}
    >
      <DeleteVariationModal
        variation={variation}
        active={showDeleteModal}
        setActive={setShowDeleteModal}
        updateVariations={updateVariations}
      />

      {editMode ? (
        <div className="rb-flex rb-items-center rb-gap-1">
          <TextField
            label="New name"
            id="nameField"
            labelHidden
            autoComplete="off"
            placeholder="Variation name"
            value={updatedName}
            onChange={handleNameChange}
            clearButton
            onClearButtonClick={() => setUpdatedName('')}
            error={inputError}
          />
          <Button
            icon={TickMinor}
            onClick={handleNameSubmit}
            loading={loading}
          />
          <Button
            icon={CancelSmallMinor}
            onClick={handleCancel}
            disabled={loading}
          />
          <InlineError
            fieldID="nameField"
            message={inputError ? "Name can't be empty" : ''}
          />
        </div>
      ) : (
        <Checkbox
          label={variation.name}
          checked={selected}
          onChange={handleClick}
        />
      )}

      <div className="rb-ml-auto rb-flex rb-gap-5">
        <div>
          <Button plain onClick={handleEdit}>
            Edit
          </Button>
        </div>
        <div style={{ visibility: isPagefly ? 'hidden' : 'visible' }}>
          <Button
            plain
            icon={DuplicateMinor}
            onClick={() => handleVariantDuplicate(variation)}
          />
        </div>
        <div style={{ visibility: isPagefly ? 'hidden' : 'visible' }}>
          <PopoverMenu
            onRename={() => setEditMode(true)}
            onDelete={() => setShowDeleteModal(true)}
          />
        </div>
      </div>
    </div>
  );
}
